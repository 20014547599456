<template>
    <v-sheet>
        <v-row class="mt-n5 align-baseline">
            <v-col class="title ml-3">기본정보</v-col>
        </v-row>
        <v-card flat class="rounded-xl mt-5">
            <v-card-text class="pl-10" style="background-color:#eeeeee; font-size:20px;">
                <v-row style="height:62px;" class="mt-3">
                    <v-col cols="3">아이디</v-col>
                    <v-col>{{adminUser.admin_user_id}}</v-col>
                </v-row>
                <v-row style="height:62px;">
                    <v-col cols="3">비밀번호</v-col>
                    <v-col>
                        <div style="float:left;">
                            <v-text-field type="password" :error="formStatus.admin_user_password.err" v-model="formData.admin_user_password" dense outlined hide-details filled background-color="white" style="width:250px;"></v-text-field>
                        </div>
                        <div style="float:left; padding-left:20px; padding-top:6px; font-size:14px; color:red;" v-if="formStatus.admin_user_password.err">
                            * {{formStatus.admin_user_password.err_msg}}
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">비밀번호 확인</v-col>
                    <v-col>
                        <div style="float:left;">
                            <v-text-field type="password" :error="formStatus.admin_user_password_confirm.err" v-model="formData.admin_user_password_confirm" dense outlined hide-details  filled background-color="white" style="width:250px;"></v-text-field>
                        </div>
                        <div style="float:left; padding-left:20px; padding-top:6px; font-size:14px; color:red;" v-if="formStatus.admin_user_password_confirm.err">
                            * {{formStatus.admin_user_password_confirm.err_msg}}
                        </div>
                    </v-col>
                </v-row>
                <v-row style="height:62px;">
                    <v-col cols="3">관리자 휴대폰</v-col>
                    <v-col>{{adminUser.admin_user_mdn | phoneNum}}</v-col>
                </v-row>
                <v-row style="height:62px;">
                    <v-col cols="3">관리자 이름</v-col>
                    <v-col>
                        <div style="float:left;">
                            <v-text-field v-model="formData.admin_user_name" :error="formStatus.admin_user_name.err" dense outlined hide-details filled background-color="white" style="width:400px;"></v-text-field>
                        </div>
                        <div style="float:left; padding-left:20px; padding-top:6px; font-size:14px; color:red;" v-if="formStatus.admin_user_name.err">
                            * {{formStatus.admin_user_name.err_msg}}
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mb-5" style="height:62px;">
                    <v-col cols="3">관리자 E-mail</v-col>
                    <v-col>
                        <div style="float:left;">
                            <v-text-field v-model="formData.admin_user_email" :error="formStatus.admin_user_email.err" dense outlined hide-details filled background-color="white" style="width:400px;"></v-text-field>
                        </div>
                        <div style="float:left; padding-left:20px; padding-top:6px; font-size:14px; color:red;" v-if="formStatus.admin_user_email.err">
                            * {{formStatus.admin_user_email.err_msg}}
                        </div>
                    </v-col>
                </v-row>
                <hr class="mb-10" />
                <v-row>
                    <v-col cols="3">사업자등록번호</v-col>
                    <v-col>{{company.corp_reg_num | corpRegNum}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">업체명</v-col>
                   <v-col>{{company.company_name}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">사업자등록증</v-col>
                    <v-col>
                        <v-btn text dense class="pl-0 pr-0" v-if="company.corp_reg_img_url" @click="openImg(company.corp_reg_img_url)"><v-icon>mdi-paperclip</v-icon>파일 보기</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center pt-10 pb-2">
                        <v-btn outlined color="grey" style="font-size:20px; width:150px; height:50px; margin:10px;" @click="doCancel">취소</v-btn>
                        <v-btn color="primary" style="font-size:20px; width:150px; height:50px; margin:10px;" @click="doSubmit">확인</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'
import adminApi from '@/api/admin'

export default {
    name: 'admin_info',
    components: {
    },
    data: () => ({
        formData: {
            admin_user_password: '',
            admin_user_password_confirm: '',
            admin_user_email: '',
            admin_user_name: '',
        },
        formStatus: { 
            admin_user_password: {
                err: false,
                err_msg: '',
            },
            admin_user_password_confirm: { 
                err: false,
                err_msg: '',
            },
            admin_user_email: { 
                err: false,
                err_msg: '',
            },
            admin_user_name: { 
                err: false,
                err_msg: '',
            }

        }
    }),
    created () { 
    },
    computed: {
        ...mapGetters({
            adminUser: 'admin/getAdminUser',
            company: 'admin/getCompany',
        }),
    },  
    mounted () { 
        this.formData.admin_user_email = this.adminUser.admin_user_email
        this.formData.admin_user_name = this.adminUser.admin_user_name  
    },
    methods: { 
        doCancel () { 
            this.$emit('goInfo')
        },
        doSubmit () { 
            let succ = true
            let pw = this.formData.admin_user_password 
            let pwCfm = this.formData.admin_user_password_confirm
            let email = this.formData.admin_user_email
            let nm = this.formData.admin_user_name

            if(!pw && !pwCfm) { 
                // not change
                this.formStatus.admin_user_password.err = false
                this.formStatus.admin_user_password.err_msg = ''

                this.formStatus.admin_user_password_confirm.err = false
                this.formStatus.admin_user_password_confirm.err_msg = ''
            } else if(pw != pwCfm) { 
                this.formStatus.admin_user_password_confirm.err = true
                this.formStatus.admin_user_password_confirm.err_msg = '비밀번호 확인이 일치하지 않습니다'
                succ = false
            } else if(pw.length < 8 || pw.length > 20) { 
                this.formStatus.admin_user_password.err = true
                this.formStatus.admin_user_password.err_msg = '8~20자로 입력해 주세요'
                succ = false
            } else {
                this.formStatus.admin_user_password_confirm.err = false
                this.formStatus.admin_user_password_confirm.err_msg = ''
            }

            var emailRegType = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

            if(!email) { 
                this.formStatus.admin_user_email.err = true
                this.formStatus.admin_user_email.err_msg = '관리자 E-mail 을 입력해 주세요'
                succ = false
            } else if(!emailRegType.test(email)) {
                this.formStatus.admin_user_email.err = true
                this.formStatus.admin_user_email.err_msg = '잘못된 E-mail 형식 입니다'
                succ = false
            } else { 
                this.formStatus.admin_user_email.err = false
                this.formStatus.admin_user_email.err_msg = ''
            }

            if(!nm) { 
                this.formStatus.admin_user_name.err = true
                this.formStatus.admin_user_name.err_msg = '관리자 이름을 입력해 주세요'
                succ = false
            } else {
                this.formStatus.admin_user_name.err = false
                this.formStatus.admin_user_name.err_msg = ''
            }

            if(!succ) { return }

            this.$store.dispatch('openConfirm', {
                message: '입력된 내용을 저장하시겠습니까?', 
                va: this,
                okCb: function(va) { 
                    adminApi.modifyInfo(
                        va.formData,
                        () => { 
                            va.$store.dispatch('openAlert', {message: '저장되었습니다', closeCb: function(va) { 
                                va.$store.dispatch('admin/getInfo', {
                                scb: () => { 
                                    va.$emit('goInfo') 
                                }, fcb: (err) => { 
                                }, va: va
                                })
                            }, va: va})
                        }, (err) => { 
                            va.$store.dispatch('openAlert', {message: '저장을 실패했습니다', sub_message: err})
                        }, va
                    )                    
                }
            })
            
        },
        openImg(url) {
        window.open(url, '_blank')
        }
    }
}
</script>
